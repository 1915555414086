/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {useEffect, useState} from 'react';
import isBillingAddressFormValid from '../../../helper/complexValidators/isBillingAddressFormValid';
import BillingAddressForm from '../../common/personalDetails/BillingAddressForm';
import SubmitButton from '../../common/buttons/SubmitButton';
import TitlePaper from '../../common/TitlePaper';
import Constants from '../../../helper/Constants';
import putUserSettings from '../../../controllers/apiCalls/put/putUserSettings';
import {useSnackbar} from 'notistack';
import {Account} from '../../../types/components/ApiTypes';
import {observer} from 'mobx-react-lite';
import getCountryId from '../../../helper/functions/getCountryId';
import getProvinceId from '../../../helper/functions/getProvinceId';

interface Props {
    account: Account,
    userId: number,
    dealer?: boolean
}

const AccountAddressPaper = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    const accountAddress = props.account?.address ? props.account.address : {
        address: '',
        city: '',
        country: '',
        postalCode: '',
        province: '',
    };

    const [ postalCode, setPostalCode ] = useState(accountAddress.postalCode);
    const [ postalCodeError, setPostalCodeError ] = useState('');
    const [ address, setAddress ] = useState(accountAddress.address);
    const [ addressError, setAddressError ] = useState('');
    const [ city, setCity ] = useState(accountAddress.city);
    const [ cityError, setCityError ] = useState('');
    const [ country, setCountry ] = useState(getCountryId(accountAddress.country).toString());
    const [ countryError, setCountryError ] = useState('');
    const [ province, setProvince ] = useState(
        getProvinceId(accountAddress.province, accountAddress.country).toString());
    const [ provinceError, setProvinceError ] = useState('');
    const [ company, setCompany ] = useState(props.dealer && props.account?.companyName
        ? props.account.companyName : '');
    const [ companyError, setCompanyError ] = useState('');

    useEffect(() => {
        if (props.account?.address.postalCode) {
            setPostalCode(props.account.address.postalCode);
        }
        if (props.account?.address.address) {
            setAddress(props.account.address.address);
        }
        if (props.account?.address.city) {
            setCity(props.account.address.city);
        }
        if (props.account?.address.country) {
            setCountry(getCountryId(props.account.address.country).toString());
            if (props.account?.address.province) {
                setProvince(getProvinceId(props.account.address.province, props.account.address.country).toString());
            }
        }
        if (props.dealer && props.account?.companyName) {
            setCompany(props.account.companyName);
        }
    }, [ props.account ]);

    const isValid = () => isBillingAddressFormValid(
            {
                value: postalCode,
                setError: setPostalCodeError,
            },
            {
                value: address,
                setError: setAddressError,
            },
            {
                value: city,
                setError: setCityError,
            }, {
                value: country,
                setError: setCountryError,
            }, {
                value: province,
                setError: setProvinceError,
            });

    const submit = () => {
        if (isValid()) {
            const settings = {
                customer: {
                    account: {
                        address: {
                            address,
                            city,
                            province,
                            country,
                            postalCode,
                        },
                        companyName: props.dealer ? company : undefined,
                    },
                },
            };
            putUserSettings(enqueueSnackbar, props.userId, settings);
        }
    };

    return (
        <TitlePaper title={Constants.ACCOUNT_ADDRESS}>
            <BillingAddressForm
                hideDivider
                id="account_address"
                postalCode={{
                    value: postalCode,
                    setValue: setPostalCode,
                    error: postalCodeError,
                    setError: setPostalCodeError,
                }}
                address={{
                    value: address,
                    setValue: setAddress,
                    error: addressError,
                    setError: setAddressError,
                }}
                town={{
                    value: city,
                    setValue: setCity,
                    error: cityError,
                    setError: setCityError,
                }}
                country={{
                    value: country,
                    setValue: setCountry,
                    error: countryError,
                    setError: setCountryError,
                }}
                province={{
                    value: province,
                    setValue: setProvince,
                    error: provinceError,
                    setError: setProvinceError,
                }}
                company={props.dealer ? {
                    value: company,
                    setValue: setCompany,
                    error: companyError,
                    setError: setCompanyError,
                } : undefined}
            />
            <SubmitButton
                id="account_address_submit"
                text={Constants.SAVE_CHANGES}
                onClick={submit}
            />
        </TitlePaper>
    );
});

export default AccountAddressPaper;
