/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import { AutomationOperationModes } from '../../types/components/ApiTypes';

const getNewBag = () => ({
    capacity: 0,
    id: -1,
    latLongOffset: false,
    latitude: 0,
    length: 0,
    width: 0,
    longitude: 0,
    name: '',
    rotation: 0,
    settings: {
        autoCapacity: true,
        autoExposeCoverSensors: true,
        autoVolume: true,
        exposedCoveredAlerts: true,
        productId: -1,
        grainLevelOffset: 0,
        hardwareType: '',
        highTemperatureThreshold: 0,
        lowTemperatureThreshold: 0,
        moistureOffset: 0,
        temperatureMonitorType: '',
        voltageAlerts: true,
        weeklyTemperatureRiseMinimumTemperature: 0,
        weeklyTemperatureRiseThreshold: 0,
        highLowTempAlerts: true,
    },
    hardware: {
        cables: [],
        fans: [],
        masterUnits: [],
        plenumSensor: {
            dateAssociated: 0,
        },
        remoteUnits: [],
        soloUnits: [],
        weatherStation: {
            dateAssociated: 0,
        },
    },
    binType: '',
    diameter: 0,
    eaveHeight: 0,
    peakHeight: 0,
    status: {
        alertStates: [],
        avgMoisture: 0,
        avgTemperature: 0,
        lastReading: 0,
        maxMoisture: 0,
        maxTemperature: 0,
        minMoisture: 0,
        minTemperature: 0,
        moistureTrend: '',
        percentFull: 0,
        temperatureTrend: '',
        volume: 0,
        plenumTemp: 0,
        plenumRh: 0,
        emc: 0,
    },
    topSvg: '',
    automationSettings: {
        structId: -1,
        operationMode: AutomationOperationModes.aeration,
        targetMoisture: 10,
        targetEmcRangeLow: 5.1,
        targetEmcRangeHigh: 12.5,
        targetTemperature: 20,
        targetTemperatureRangeLow: -5,
        targetTemperatureRangeHigh: 20,
        plenumRise: 2.5,
        plenumOffset: 2.5,
        minimumAllowedTemperature: 0,
        running: false,
    },
    sortOrder: 0,
});

export default getNewBag;
