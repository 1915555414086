import { useEffect, useState } from "react";
import Colors from "../../helper/Colors";
import WebStore from "../../stores/WebStore";
import {observer} from 'mobx-react-lite';

const BulkEditIcon = observer(() => {
    const DARK_GREY = '#718096';
    const WHITE = '#FFFFFF';
    const [ backGround, setBackGround ] = useState(Colors.background);
    const [ foreGround, setForeGround ] = useState(DARK_GREY);

    useEffect(() => {
        if (WebStore.lightMode) {
            setForeGround(DARK_GREY)
            setBackGround(Colors.background);
        } else {
            setForeGround(WHITE);
            setBackGround(Colors.backgroundDark);
        }
    }, [ WebStore.lightMode ]);
    
    return (
        <svg
            width="25"
            height="25"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            overflow="hidden"
            viewBox="-50 -50 742 640"
        >
            <g transform="translate(-2228 -533)">
                <path
                    d="M2228 630.649 2356.69 533 2382.31 533.278 2511 630.649 2511 963 2228 963 2228 630.649Z"
                    fill={backGround}
                    fill-rule="evenodd"
                />
                <path
                    d="M2266 649.649 2394.23 552 2419.77 552.278 2548 649.649 2548 982 2266 982 2266 649.649Z"
                    fill={foreGround}
                    fill-rule="evenodd"
                />
                <path
                    d="M2350 685.649 2478.69 588 2504.31 588.278 2633 685.649 2633 1018 2350 1018 2350 685.649Z"
                    fill={backGround}
                    fill-rule="evenodd"
                />
                <path
                    d="M2388 704.649 2516.69 607 2542.31 607.278 2671 704.649 2671 1037 2388 1037 2388 704.649Z"
                    fill={foreGround}
                    fill-rule="evenodd"
                />
                <path
                    d="M2473 741.649 2601.69 644 2627.31 644.278 2756 741.649 2756 1074 2473 1074 2473 741.649Z"
                    fill={backGround}
                    fill-rule="evenodd"
                />
                <path
                    d="M2511 759.877 2639.69 662 2665.31 662.279 2794 759.877 2794 1093 2511 1093 2511 759.877Z"
                    fill={foreGround}
                    fill-rule="evenodd"
                />
                <path
                    d="M2599.9 1172.5 2599.9 1172.5 2599.9 1056.78 2853.93 802.749 2853.93 802.749 2969.65 918.468 2969.65 918.468 2715.62 1172.5Z"
                    fill={backGround}
                    fill-rule="evenodd"
                />
                <path
                    d="M2640.88 1130.75 2640.88 1130.75 2640.88 1074.9 2804.85 910.929 2804.85 910.929 2860.7 966.779 2860.7 966.779 2696.73 1130.75Z"
                    fill={foreGround}
                    fill-rule="evenodd"
                />
                <path
                    d="M2869.94 871.67 2899.96 901.692C2907.09 908.824 2907.09 920.388 2899.96 927.52L2875.93 951.545 2820.08 895.695 2844.11 871.67C2851.24 864.538 2862.8 864.538 2869.94 871.67Z"
                    fill={foreGround}
                    fill-rule="evenodd"
                />
            </g>
        </svg>
    );
});

export default BulkEditIcon;