/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import DisplayGridLabel from '../../../common/displayTable/DisplayGridLabel';
import DisplayGridContent from '../../../common/displayTable/DisplayGridContent';
import {Command} from '../../../../types/components/ApiTypes';
import useWindowSize from '../../../../helper/hooks/useWindowSize';
import {useMemo} from 'react';
import Constants from '../../../../helper/Constants';
import moment from 'moment';

interface Props {
    row: Command,
}

const ServerCommandHistoryTableRow = (props: Props) => {
    const windowSize = useWindowSize();
    const windowWidth = useMemo(() => windowSize[0], [ windowSize ]);
    const largeScreen = useMemo(() => windowWidth > Constants.WINDOW_WIDTHS[15], [ windowSize ]);

    const renderGridLabel = (id: string, label: string, xs: number, lengthLimit?: number) => (
        <DisplayGridLabel
            id={`${ props.row.serial }_command_history_${ id }`}
            label={label}
            xs={xs}
            lengthLimit={lengthLimit}
        />
    );

    const getFormattedDate = (time: number) => {
        if (time > 0) {
            const dt = moment(new Date(time));
            return dt.format('MM/DD/YYYY').concat(' at ').concat(dt.format('hh:mm A'));
        } else {
            return Constants.NA;
        }
    };

    return (
        <DisplayGridContent
            topMargin='0px'
        >
            {renderGridLabel('serial', props.row.serial, 1.5, largeScreen ? 9 : 6)}
            {renderGridLabel('status', props.row.status, 1.5)}
            {renderGridLabel('details', props.row.commandText, 3)}
            {renderGridLabel('queue_time', getFormattedDate(props.row.queueTime), 1.5)}
            {renderGridLabel('sent_time', getFormattedDate(props.row.sentTime), 1.5)}
            {renderGridLabel('reply', props.row.replyText, 3)}
        </DisplayGridContent>
    );
};

export default ServerCommandHistoryTableRow;
