/*
 * Copyright © 2022 Calian Ltd.  All rights reserved.
 */

import {styled} from '@mui/material';
import {observer} from 'mobx-react-lite';
import {useEffect, useMemo, useState} from 'react';
import Constants from '../../../../helper/Constants';
import ViewDeviceDisplayPage from './display/ViewDeviceDisplayPage';
import ViewDeviceSettingsPage from './settings/ViewDeviceSettingsPage';
import NotesTable from '../../notes/NotesTable';
import BinsenseStore from '../../../../stores/BinsenseStore';
import WebStore from '../../../../stores/WebStore';
import getIndex from '../../../../helper/functions/getIndex';
import ReportsLogsPage from '../../reports/logs/ReportsLogsPage';
import ViewDevicePageHeader from './ViewDevicePageHeader';
import {Site} from '../../../../types/components/ApiTypes';
import getBinNotes from '../../../../controllers/apiCalls/get/getBinNotes';
import {useSnackbar} from 'notistack';
import getNewBin from '../../../../helper/functions/getNewBin';
import binHasAutomationRemote from '../../../../helper/functions/binHasAutomationRemote';
import siteHasAutomationHub from '../../../../helper/functions/siteHasAutomationHub';

const Root = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
});

interface Props {
    site: Site,
}

const ViewDevicePage = observer((props: Props) => {
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
        if (WebStore.selectedView === Constants.DEALER && props.site.id !== -1) {
            getBinNotes(enqueueSnackbar, props.site.id);
        }
    }, [ props.site ]);

    const deviceIndex = useMemo(() => getIndex(WebStore.viewDeviceId, props.site.bins),
        [ WebStore.viewDeviceId, props.site ]);
    const grainDeviceIndex = useMemo(() => getIndex(WebStore.viewDeviceId, props.site.grainBags),
        [ WebStore.viewDeviceId, props.site ]);
    const buildingDeviceIndex = useMemo(() => getIndex(WebStore.viewDeviceId, props.site.buildings),
        [ WebStore.viewDeviceId, props.site ]);

    useEffect(() => {
        if (deviceIndex === -1 && grainDeviceIndex === -1 && buildingDeviceIndex === -1) {
            if (props.site.bins.length > 0) {
                WebStore.setViewDeviceId(props.site.bins[0].id, true);
            } else if (props.site.grainBags.length > 0) {
                WebStore.setViewDeviceId(props.site.grainBags[0].id, true);
            } else if (props.site.buildings.length > 0) {
                WebStore.setViewDeviceId(props.site.buildings[0].id, true);
            }
        }
    }, [ props.site ]);

    const notes = useMemo(() => BinsenseStore.notesTableData
                .filter(note => note.structureId === WebStore.viewDeviceId),
        [ BinsenseStore.notesTableData, props.site, deviceIndex, grainDeviceIndex, buildingDeviceIndex ]);

    const [ tab, setTab ] = useState(Constants.DISPLAY);

    const bin = useMemo(() => {
        if (props.site.grainBags.length > 0 && grainDeviceIndex > -1) {
            return props.site.grainBags[grainDeviceIndex];
        } if (props.site.buildings.length > 0 && buildingDeviceIndex > -1) {
            return props.site.buildings[buildingDeviceIndex];
        } else if (props.site.bins.length > 0 && deviceIndex > -1) {
            return props.site.bins[deviceIndex > -1 ? deviceIndex : 0];
        }
    return getNewBin();
}, [ props.site, deviceIndex, grainDeviceIndex, buildingDeviceIndex ]);

    const [ selectedFanId, setSelectedFanId ] = useState<string | number>('All');

    useEffect(() => setSelectedFanId('All'), [ WebStore.selectedSiteId, WebStore.viewDeviceId ]);

    const renderContent = () => {
        switch (tab) {
            case Constants.DISPLAY:
                return (
                    <ViewDeviceDisplayPage
                        bin={bin}
                        fanIdField={{
                            value: selectedFanId,
                            setValue: setSelectedFanId,
                        }}
                        hasHub={siteHasAutomationHub(props.site)}
                        siteId={props.site.id}
                    />
                );
            case Constants.NOTES:
                return (
                    <NotesTable
                        rows={notes}
                        site={props.site}
                    />
                );
            case Constants.SETTINGS:
                return (
                    <ViewDeviceSettingsPage
                        bin={bin}
                        disableAutomationSettings={!binHasAutomationRemote(bin) ||
                            !siteHasAutomationHub(props.site) ||
                            bin.settings.productId === 33 }
                    />
                );
            case Constants.ACTIVITY:
                return (
                    <ReportsLogsPage
                        site={props.site}
                        noHeaderOptions
                        displayOnlyTimePicker
                    />
                );
        }
    };

    return (
        <Root>
            <ViewDevicePageHeader
                site={props.site}
                bin={bin}
                tab={tab}
                setTab={setTab}
            />
            <div style={{ width: '100%' }}>
                {renderContent()}
            </div>
        </Root>
    );
});

export default ViewDevicePage;
