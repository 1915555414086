/*
 * Copyright © 2021 Calian Ltd.  All rights reserved.
 */

import {Bin, Building, GrainBag, Pile, Site} from '../../types/components/ApiTypes';
import getBinFanStatus from './getBinFanStatus';
import { comparisonOfStructuresInAscendingOrder } from './functions';
import Constants from '../Constants';

const getSpecificStructures = (site: Site, key: string, canShowDevice: boolean) => site[key]
.map((structure: Bin | Building | GrainBag | Pile) => ({
    alerts: structure.settings.temperatureMonitorType,
    fanControl: key === 'bins' ? getBinFanStatus((<Bin> structure).hardware.fans) : 'No Fans',
    fanRunHours: 0,
    highTempThreshold: structure.settings.highTemperatureThreshold,
    id: structure.id,
    lowTempThreshold: structure.settings.lowTemperatureThreshold,
    name: structure.name,
    selected: false,
    typeKey: key,
    weeklyTempRise: structure.settings.weeklyTemperatureRiseThreshold,
    showDevice: canShowDevice,
    sortOrder: structure.sortOrder,
    grainId: structure.settings.productId,
    autoLevel: structure.settings.autoExposeCoverSensors,
    hardwareType: structure.settings.hardwareType,
    highLowTempAlerts: structure.settings.highLowTempAlerts ? 'On' : 'Off',
    voltageAlerts: structure.settings.voltageAlerts ? 'On' : 'Off',
    autoLevelText: (structure.settings.hardwareType.toLowerCase() === Constants.LIVE.toLowerCase() ||
        structure.settings.hardwareType.toLowerCase() === Constants.PLUS.toLowerCase())
        ? (structure.settings.autoExposeCoverSensors ? Constants.ON : Constants.MANUAL) : Constants.NA,
}));

const getStructuresFromSite = (site: Site, canShowDevice: boolean) => {
    const bins = getSpecificStructures(site, 'bins', canShowDevice);
    const buildings = getSpecificStructures(site, 'buildings', canShowDevice);
    const grainBags = getSpecificStructures(site, 'grainBags', canShowDevice);
    const array = [ ...bins, ...buildings, ...grainBags ];

    const clonedData = JSON.parse(JSON.stringify(array));
    return comparisonOfStructuresInAscendingOrder(clonedData);
};

export default getStructuresFromSite;
