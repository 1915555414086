import { useEffect, useState } from "react";
import Colors from "../../helper/Colors";
import WebStore from "../../stores/WebStore";
import Constants from "../../helper/Constants";
import {observer} from 'mobx-react-lite';

const BulkEditIconC2 = observer(() => {

    const GREY = '#727374';
    const WHITE = '#FFFFFF';
    const [ backGround, setBackGround ] = useState(Colors.background);
    const [ foreGround, setForeGround ] = useState(GREY);
    const [ fillOpacity, setFillOpacity ] = useState('1');

    useEffect(() => {
        if (WebStore.selectedDrawer === Constants.BULK_CHANGES){
            setForeGround(Colors.primaryDark);
            setFillOpacity('0.5');
        } else {
            if (WebStore.lightMode) {
                setForeGround(GREY);
            } else {
                setForeGround(WHITE);
            }
            setFillOpacity('1');
        }

        if (WebStore.lightMode) {
            setBackGround(Colors.background);
        } else {
            setBackGround(Colors.backgroundDark);
        }
    }, [ WebStore.selectedDrawer, WebStore.lightMode ]);

    return (
        <svg
            width="25"
            height="25"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            overflow="hidden"
            viewBox="-50 -50 848 732"
        >
            <g transform="translate(-1277 -930)">
                <path
                    d="M1277 1041.73 1423.88 930 1453.12 930.318 1600 1041.73 1600 1422 1277 1422 1277 1041.73Z"
                    fill={backGround}
                    fill-rule="evenodd"
                    fill-opacity={fillOpacity}
                />
                <path
                    d="M1320 1063.73 1466.88 952 1496.12 952.318 1643 1063.73 1643 1444 1320 1444 1320 1063.73Z"
                    fill={foreGround}
                    fill-rule="evenodd"
                />
                <path
                    d="M1417 1105.73 1563.88 994 1593.12 994.318 1740 1105.73 1740 1486 1417 1486 1417 1105.73Z"
                    fill={backGround}
                    fill-rule="evenodd"
                    fill-opacity={fillOpacity}
                />
                <path
                    d="M1460 1126.73 1606.88 1015 1636.12 1015.32 1783 1126.73 1783 1507 1460 1507 1460 1126.73Z"
                    fill={foreGround}
                    fill-rule="evenodd"
                />
                <path
                    d="M1557 1168.73 1703.88 1057 1733.12 1057.32 1880 1168.73 1880 1549 1557 1549 1557 1168.73Z"
                    fill={backGround}
                    fill-rule="evenodd"
                    fill-opacity={fillOpacity}
                />
                <path
                    d="M1600 1189.73 1746.88 1078 1776.12 1078.32 1923 1189.73 1923 1570 1600 1570 1600 1189.73Z"
                    fill={foreGround}
                    fill-rule="evenodd"
                />
                <path
                    d="M1701.95 1661.63 1701.95 1661.63 1701.95 1529.3 1992.44 1238.81 1992.44 1238.81 2124.77 1371.14 2124.77 1371.14 1834.28 1661.63Z"
                    fill={backGround}
                    fill-rule="evenodd"
                    fill-opacity={fillOpacity}
                />
                <path
                    d="M1748.81 1613.89 1748.81 1613.89 1748.81 1550.02 1936.32 1362.52 1936.32 1362.52 2000.18 1426.39 2000.18 1426.39 1812.68 1613.89Z"
                    fill={foreGround}
                    fill-rule="evenodd"
                />
                <path
                    d="M2010.75 1317.63 2045.08 1351.96C2053.23 1360.11 2053.23 1373.34 2045.08 1381.49L2017.6 1408.97 1953.74 1345.1 1981.21 1317.63C1989.37 1309.47 2002.59 1309.47 2010.75 1317.63Z"
                    fill={foreGround}
                    fill-rule="evenodd"
                />
            </g>
        </svg>
    );
});
export default BulkEditIconC2;