/*
 * Copyright © 2023 Calian Ltd.  All rights reserved.
 */

import DeviceSettingsTable from './DeviceSettingsTable';
import {useEffect, useMemo, useState} from 'react';
import EditSelectedDevicesModal from './EditSelectedDevicesModal';
import BinsenseStore from '../../../stores/BinsenseStore';
import {observer} from 'mobx-react-lite';
import {DeviceSettingsStructure, Site} from '../../../types/components/ApiTypes';
import getStructuresFromSite from '../../../helper/functions/getStructuresFromSite';
import {Button, styled} from '@mui/material';
import getTestID from '../../../helper/functions/getTestID';
import Constants from '../../../helper/Constants';
import exportTableToCSV from '../../../helper/functions/exportTableToCSV';
import {Download} from '@mui/icons-material';
import DashboardSkeleton from '../../common/DashboardSkeleton';
import EditBinOrderModal from '../sortOrder/EditBinOrderModal';
import WebStore from '../../../stores/WebStore';
import EditSelectedBinsModal from './EditSelectedBinsModal';
import EditSelectedBinSizesModal from './EditSelectedBinSizesModal';

const Row = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'right',
    paddingBottom: '12px',
    paddingRight: '20px',
    paddingTop: '12px',
});

interface Props {
    site: Site,
    showDevice: boolean,
}

const SettingsPage = observer((props: Props) => {
    const rows = useMemo(() => BinsenseStore.deviceTableData, [ BinsenseStore.deviceTableData ]);

    const exportColumns = [
        {
            field: 'name',
            headerName: Constants.STRUCTURE,
        },
        {
            field: 'alerts',
            headerName: Constants.HIGH_TEMP_ALERTS,
        },
        {
            field: 'weeklyTempRise',
            headerName: Constants.WEEKLY_TEMP_RISE,
        },
        {
            field: 'fanControl',
            headerName: Constants.FAN_CONTROL,
        },
    ];

    useEffect(() => BinsenseStore.setDeviceTableData(getStructuresFromSite(props.site, props.showDevice)), [ props.site ]);

    const [ editSelectedDevicesModalOpen, setEditSelectedDevicesModalOpen ] = useState(false);
    const [ editBinOrderModalOpen, setEditBinOrderModalOpen ] = useState(false);
    const [ editingId, setEditingId ] = useState<number | null>(null);

    const disableEditButton = useMemo(() => {
        const selectedRows = BinsenseStore.deviceTableData
            .filter((structure: DeviceSettingsStructure) => structure.selected);
        return selectedRows.length === 0;
    }, [ BinsenseStore.deviceTableData ]);

    const disableEditBinSizesButton = useMemo(() => {
        const selectedBins = BinsenseStore.deviceTableData
            .filter((structure: DeviceSettingsStructure) => structure.selected && structure.typeKey === 'bins');
        const selectedNonBins = BinsenseStore.deviceTableData
            .filter((structure: DeviceSettingsStructure) => structure.selected && structure.typeKey !== 'bins');

        return !(selectedBins.length > 0 && selectedNonBins.length === 0);
    }, [ BinsenseStore.deviceTableData ]);

    const disableEditOrderButton = props.site.id > 0 ? false : true;

    const isDealer = WebStore.selectedView === Constants.DEALER;
    const [ editSelectedBinsModalOpen, setEditSelectedBinsModalOpen ] = useState(false);
    const [ editSelectedBinSizesModalOpen, setEditSelectedBinSizesModalOpen ] = useState(false);

    return (
        <div style={{ width: '100%' }}>
            {!BinsenseStore.awaitingUserData && !BinsenseStore.awaitingApi ? (
                <Row>
                    <Button
                        {...getTestID('edit_selected_bins_button')}
                        onClick={() => setEditSelectedBinsModalOpen(true)}
                        variant='contained'
                        disabled={disableEditButton}
                    >
                        Edit Selected Bins
                    </Button>
                    {isDealer &&
                        <Button
                            {...getTestID('edit_selected_bin_size_button')}
                            onClick={() => setEditSelectedBinSizesModalOpen(true)}
                            variant='contained'
                            style={{ marginLeft: '8px' }}
                            disabled={disableEditButton || disableEditBinSizesButton}
                        >
                            Edit Selected Bin Sizes
                        </Button>
                    }
                    <Button
                        {...getTestID('edit_bin_order_button')}
                        onClick={() => setEditBinOrderModalOpen(true)}
                        variant='contained'
                        style={{ marginLeft: '8px' }}
                        disabled={disableEditOrderButton}
                    >
                        {Constants.EDIT_ORDER}
                    </Button>
                    <Button
                        {...getTestID('export_button')}
                        onClick={() => exportTableToCSV(exportColumns, rows.map(row => ({
                            ...row,
                            alerts: row.alerts === 'thresholds' ? row.highTempThreshold : row.alerts,
                        })), 'device_settings')}
                        variant='contained'
                        style={{ marginLeft: '8px' }}
                        startIcon={<Download/>}
                    >
                        {Constants.EXPORT}
                    </Button>
                </Row>
            ) : (
                <Row>
                    <DashboardSkeleton
                        height={40}
                        width='174px'
                        style={{ marginRight: '8px' }}
                    />
                    <DashboardSkeleton
                        height={40}
                        width='174px'
                        style={{ marginRight: '8px' }}
                    />
                    <DashboardSkeleton
                        height={40}
                        width='174px'
                        style={{ marginRight: '8px' }}
                    />
                    <DashboardSkeleton
                        height={40}
                        width='100px'
                    />
                </Row>
            )}
            <DeviceSettingsTable
                setEditingId={setEditingId}
                setModalOpen={setEditSelectedDevicesModalOpen}
                tableData={rows}
            />
            {editSelectedDevicesModalOpen && (
                <EditSelectedDevicesModal
                    open
                    close={() => {
                        setEditingId(null);
                        setEditSelectedDevicesModalOpen(false);
                    }}
                    id={editingId}
                    devices={rows}
                    siteId={props.site.id}
                />
            )}
            {editBinOrderModalOpen && (
                <EditBinOrderModal
                    close={() => {
                        setEditBinOrderModalOpen(false);
                    }}
                    site={props.site}
                />
            )}
            {editSelectedBinsModalOpen && (
                <EditSelectedBinsModal
                    open
                    close={() => {
                        setEditSelectedBinsModalOpen(false);
                    }}
                    devices={rows}
                    siteId={props.site.id}
                />
            )}
            {editSelectedBinSizesModalOpen && (
                <EditSelectedBinSizesModal
                    open
                    close={() => {
                        setEditSelectedBinSizesModalOpen(false);
                    }}
                    devices={rows}
                    site={props.site}
                />
            )}
        </div>
    );
});

export default SettingsPage;
