import {styled, Typography} from '@mui/material';
import {observer} from 'mobx-react-lite';
import Constants from '../../../../helper/Constants';
import GridTextFieldInput from '../../../common/input/GridTextFieldInput';
import GridSelectFieldInput from '../../../common/input/GridSelectFieldInput';
import Grid from '@mui/material/Grid';
import {BinType, InputFieldProps} from '../../../../types/components/ApiTypes';
import getTestID from '../../../../helper/functions/getTestID';
import StructureSetupOptions from './StructureSetupOptions';

const Root = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '8px',
    width: '100%',
});

const Row = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '16px',
});

interface Props {
    structure: InputFieldProps,
    useDimensions: boolean,
    peakHeight: InputFieldProps,
    eaveHeight: InputFieldProps,
    diameter: InputFieldProps,
    bushelCapacity: InputFieldProps,
    powerSource: InputFieldProps,
    heightError: string,
    tab: string,
    onTabChange: (event: any, newValue: string) => void,
}

const BinSizesForm = observer((props: Props) => {
    const updateProp = (key: string, value: string) => {
        if (props[key].setValue) {
            props[key].setValue(value);
        }
    };

    const onStructureUpdate = (value: string) => {
        updateProp('peakHeight', '');
        updateProp('eaveHeight', '');
        updateProp('diameter', '');
        updateProp('bushelCapacity', '');
        updateProp('structure', value);
    };

    return (
        <Root>
            <Row>
                <StructureSetupOptions
                    tab={props.tab}
                    onTabChange={props.onTabChange}
                />
            </Row>
            <Grid
                container
                spacing={2}
            >
                <GridSelectFieldInput
                    id="bin_details_structure_input"
                    label={Constants.STRUCTURE_TYPE}
                    field={{
                        ...props.structure,
                        setValue: onStructureUpdate,
                    }}
                    options={[
                        {
                            id: 'flat',
                            title: BinType.flat,
                        },
                        {
                            id: 'hopper',
                            title: BinType.hopper,
                        },
                    ]}
                />

                {props.useDimensions &&
                    <>
                        <GridTextFieldInput
                            id="bin_details_peak_height_input"
                            label={Constants.PEAK_HEIGHT}
                            field={props.peakHeight}
                            textFieldProps={{
                                inputProps: {
                                    type: 'number',
                                    min: '0',
                                },
                            }}
                        />
                        <GridTextFieldInput
                            id="bin_details_eave_height_input"
                            label={Constants.EAVE_HEIGHT}
                            field={props.eaveHeight}
                            textFieldProps={{
                                inputProps: {
                                    type: 'number',
                                    min: '0',
                                },
                            }}
                        />
                    </>
                }

                { !props.useDimensions &&
                    <GridTextFieldInput
                        id="bin_details_bushel_capacity_input"
                        label={Constants.BUSHEL_CAPACITY}
                        field={props.bushelCapacity}
                        textFieldProps={{
                            inputProps: {
                                type: 'number',
                                min: '0',
                            },
                        }}
                    />
                }
                <GridTextFieldInput
                    id="bin_details_diameter_input"
                    label={Constants.DIAMETER}
                    field={props.diameter}
                    textFieldProps={{
                        inputProps: {
                            type: 'number',
                            min: '0',
                        },
                        // disabled: true,
                    }}
                />
                <GridSelectFieldInput
                    id="bin_details_power_source_input"
                    label={Constants.POWER_SOURCE}
                    field={props.powerSource}
                    options={[
                        {
                            id: 'battery_powered',
                            title: Constants.BATTERY_POWERED,
                        },
                        {
                            id: 'plugged_in',
                            title: Constants.PLUGGED_IN,
                        },
                    ]}
                />
            </Grid>
            {props.heightError !== '' && (
                <Typography
                    {...getTestID('height_error')}
                    color='error'
                >
                    {props.heightError}
                </Typography>
            )}
        </Root>
    );
});

export default BinSizesForm;
