import callPutAPI from '../../callPutAPI';
import updateData from '../../../helper/functions/updateData';

const putBins = (enqueueSnackbar: any, siteId: number, body: any) => {
    callPutAPI(`bins/${ siteId }`,
        body,
        (response) => {
            if (response === 'OK') {
                updateData(enqueueSnackbar);
            }
        },
        enqueueSnackbar,
        'Bin settings updated successfully'
    );
};

export default putBins;