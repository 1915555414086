import { Typography, styled } from '@mui/material';
import BaseModal from '../../common/modal/BaseModal';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import Constants from '../../../helper/Constants';
import { BinType, DeviceSettingsStructure, Site, SiteStructures } from '../../../types/components/ApiTypes';
import BinSizesForm from '../../dealer/createSite/binSetupModal/BinSizesForm';
import { useSnackbar } from 'notistack';
import WebStore from '../../../stores/WebStore';
import putBins from '../../../controllers/apiCalls/put/putBins';

const Container = styled('div')({
    width: '725px',
    paddingLeft: '25px',
    paddingRight: '25px',
    minHeight: '300px',
});

interface Props {
    open: boolean,
    close: () => void,
    devices: DeviceSettingsStructure[],
    site: Site,
}

const EditSelectedBinSizesModal = observer((props: Props) => {
    const { enqueueSnackbar } = useSnackbar();

    const [ structure, setStructure ] = useState( BinType.flat);
    const [ structureError, setStructureError ] = useState('');
    const [ peakHeight, setPeakHeight ] = useState('');
    const [ peakHeightError, setPeakHeightError ] = useState('');
    const [ eaveHeight, setEaveHeight ] = useState('');
    const [ eaveHeightError, setEaveHeightError ] = useState('');
    const [ diameter, setDiameter ] = useState('');
    const [ diameterError, setDiameterError ] = useState('');
    const [ powerSource, setPowerSource ] = useState(Constants.BATTERY_POWERED);
    const [ powerSourceError, setPowerSourceError ] = useState('');
    const [ heightError, setHeightError ] = useState('');
    const [ bushelCapacity, setBushelCapacity ] = useState('');
    const [ bushelCapacityError, setBushelCapacityError ] = useState('');
    const [ useDimensions, setUseDimensions ] = useState(false);
    const [ tab, setTab ] = useState(Constants.BUSHEL_CAPACITY);

    const resetTabValues = () => {
        setPeakHeight('');
        setEaveHeight('');
        setBushelCapacity('');
    };

    const handleTabChange = (event: any, newValue: string) => {
        setUseDimensions(newValue === Constants.DIMENSIONS);
        setTab(newValue);
        resetTabValues();
    };

    const isValid = () => {
        let retValue = true;
        if (Number(peakHeight) < Number(eaveHeight)) {
            retValue = false;
            setHeightError('The eave height must be less than the peak height.');
        }
        return retValue;
    };

    const resetErrors = () => {
        setHeightError('');
    };

    const getHardwareData = (id: number) => {
        let hardware = undefined;
        for (let i = 0; i < props.site.bins.length; i++) {
            const bin = props.site.bins[i];
            if (bin.id === id) {
                hardware = bin.hardware;
                if (hardware.masterUnits !== null) {
                    hardware.masterUnits.forEach(masterUnit => {
                        masterUnit.status.acPower = powerSource === Constants.PLUGGED_IN;
                    });
                }
                if (hardware.remoteUnits !== null) {
                    hardware.remoteUnits.forEach(remoteUnit => {
                        remoteUnit.status.acPower = powerSource === Constants.PLUGGED_IN;
                    });
                }
                break;
            }
        }
        return hardware;
    };

    const editDataMultiple = () => {
        const bins: any[] = [];
        props.devices.forEach(device => {
            if (device.selected && device.typeKey === 'bins') {
                const body = {
                    id: device.id,
                    binType: structure.toString().toLowerCase(),
                    peakHeight: peakHeight !== '' ? peakHeight : undefined,
                    eaveHeight: eaveHeight !== '' ? eaveHeight : undefined,
                    capacity: bushelCapacity !== '' ? bushelCapacity : undefined,
                    diameter: diameter !== '' ? diameter : undefined,
                    hardware: powerSource !== '' ? getHardwareData(device.id) : undefined,
                };
                bins.push(body);
            }
        });
        if (bins.length > 0) {
            const siteStructures: SiteStructures = {
                id: props.site.id ? props.site.id : WebStore.selectedSiteId,
                automationHubs: [],
                bins: [],
                buildings: [],
                grainBags: [],
                structNames: [],
                dataGeneratedTime: new Date(),
            };
            if (bins.length > 0) {
                siteStructures.bins = bins;
            }
            putBins(enqueueSnackbar, props.site.id ? props.site.id : WebStore.selectedSiteId, siteStructures);
        }
    };

    const submit = () => {
        resetErrors();
        if (isValid()) {
            editDataMultiple();
            props.close();
        }
    };

    return (
        <BaseModal
            id="edit_selected_bin_sizes_modal"
            open={props.open}
            close={props.close}
            submit={submit}
            title={'Edit Selected Bin Sizes'}
            disableSubmitOnAwait
        >
            <Typography
                sx={{
                    fontSize: '15px',
                    paddingLeft: '16px',
                }}
            >
                All selected bins will be changed to the following size parameters.
            </Typography>
            <Container>
                <BinSizesForm
                    useDimensions={useDimensions}
                    tab={tab}
                    onTabChange={handleTabChange}
                    structure={{
                        value: structure,
                        setValue: setStructure,
                        error: structureError,
                        setError: setStructureError,
                    }}
                    peakHeight={{
                        value: peakHeight,
                        setValue: setPeakHeight,
                        error: peakHeightError,
                        setError: setPeakHeightError,
                    }}
                    eaveHeight={{
                        value: eaveHeight,
                        setValue: setEaveHeight,
                        error: eaveHeightError,
                        setError: setEaveHeightError,
                    }}
                    bushelCapacity={{
                        value: bushelCapacity,
                        setValue: setBushelCapacity,
                        error: bushelCapacityError,
                        setError: setBushelCapacityError,
                    }}
                    diameter={{
                        value: diameter,
                        setValue: setDiameter,
                        error: diameterError,
                        setError: setDiameterError,
                    }}
                    powerSource={{
                        value: powerSource,
                        setValue: setPowerSource,
                        error: powerSourceError,
                        setError: setPowerSourceError,
                    }}
                    heightError={heightError}
                />
            </Container>
        </BaseModal>
    );
});

export default EditSelectedBinSizesModal;